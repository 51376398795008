import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"


const GalaPage = () => (
  <Layout>
    <SEO title="Gala" />
    <p>Waerde Lords,</p>

    <p>Na het openingsfeest en de pubquiz is het tijd voor ons Gala! Dit Lustrum zullen we ons op 14 maart 2020 met alle Lords ons verplaatsten naar Kasteel de Berckt te Baarlo. Hier zullen we samen met onze dates genieten van een zorgvuldig samengesteld Heerendiner in combinatie met onbeperkt pils en wijn. Volgens de weerberichten bestaat er ook een 100% kans dat er whisky en sigaren gecombineerd gaan worden. Na het diner zal het feest losbarsten met een band en verscheidene dj’s. Als afsluiting van de avond hebben wij geregeld dat elke Lord een tweepersoonskamer tot zijn beschikking heeft om dingen te doen.</p>

    <p>Dus:<br />
      14 maart 2020<br />
      Kasteel de Berckt te Baarlo<br />
      Onbeperkt drank<br />
      135 euro pp (270 per lord)
</p>
  </Layout>
)

export default GalaPage
